import EventDispatcher from "./event-dispatcher";

/**
 * Dispatch async events and return bool if all events have passed
 */
export default class {
    constructor() {
        this.events = {};
    }

    /**
     * @param {string} eventName
     * @param data
     * @returns {Promise<boolean>}
     */
    async dispatch(eventName, data) {
        const event = this._getEvent(eventName);
        if (event) {
            const callbacks = await event.fire(data);
            return callbacks.length === event.callbacks.length;
        }
        return true;
    }

    /**
     * @param {string} eventName
     * @param {fn} callback
     */
    on(eventName, callback) {
        let event = this._getEvent(eventName);
        if (!event) {
            event = new EventDispatcher(eventName);
            this.events[eventName] = event;
        }
        event.registerCallback(callback);
    }

    /**
     * @param {string} eventName
     * @param {fn} callback
     */
    off(eventName, callback) {
        const event = this._getEvent(eventName);
        if (event && event.callbacks.indexOf(callback) > -1) {
            event.unregisterCallback(callback);
            if (event.callbacks.length === 0) {
                delete this.events[eventName];
            }
        }
    }

    /**
     * @param {string} eventName
     * @returns {null}
     * @private
     */
    _getEvent(eventName) {
        return this.events[eventName] || null;
    }
}
