import { commitPayload } from "@/utils/helpers/store";

export default {
  initialiseVehicle(context, vehicle) {
    commitPayload(context, vehicle);
  },

  /**
   * Update the vehicle state when using vehicle lookup object.
   * @param commit
   * @param next
   * @param current
   */
  async updateVehicleFromLookup({ commit }, { next, current }) {
    commit(`setFromSearch`, next);
  },

  /**
   * Update the vehicle state
   * @param commit
   * @param next
   * @param current
   */
  async updateVehicle({ commit }, { next, current }) {
    Object.keys(next).map((nextIndex) => {
      if (
        typeof current[nextIndex] !== "undefined" &&
        current[nextIndex] !== next[nextIndex]
      ) {
        commit(
          `set${nextIndex.replace(/^\w/, (c) => c.toUpperCase())}`,
          next[nextIndex]
        );
      }
    });
  },

  /**
   * Update a vehicle attribute
   * @param key
   * @param value
   */
  updateAttribute({ commit }, { key, value }) {
    commit(`set${key.replace(/^\w/, (c) => c.toUpperCase())}`, value);
  },
};
