export default {
  getTitle: (state) => state.title,
  getGender: (state) => state.gender,
  getFirstName: (state) => state.firstName,
  getLastName: (state) => state.lastName,
  getDateOfBirth: (state) => state.dateOfBirth,
  getNoClaimsDiscount: (state) => state.noClaimsDiscount,
  getEmailAddress: (state) => state.emailAddress,
  getTelephoneNumber: (state) => state.telephoneNumber,
  getOccupations: (state) => state.occupations,
  getCommunicationPreferences: (state) => state.communicationPreferences,
  getAddress: (state) => state.address,

  getUsageFrequency: (state) => state.usageFrequency,
  getEmploymentStatus: (state) => state.employmentStatus,
  getPermanentResidency: (state) => state.permanentResidency,
  getDateToUK: (state) => state.dateToUK,
  getMaritalStatus: (state) => state.maritalStatus,
  getLicenceType: (state) => state.licenceType,
  getLicenceDate: (state) => state.licenceDate,
  getAccessOtherCars: (state) => state.accessOtherCars,
  getCarsInFamily: (state) => state.carsInFamily,
  getHomeOwner: (state) => state.homeOwner,
  getRefusedCover: (state) => state.refusedCover,
  getKeptLocation: (state) => state.keptLocation,
  getKeptAddress: (state) => state.keptAddress,
  getKeptAtProposerAddress: (state) => state.keptAtProposerAddress,
  getDrivers: (state) => state.drivers,
  getClaims: (state) => state.claims,
  getMedicalConditions: (state) => state.medicalConditions,
  getCriminalConvictions: (state) => state.criminalConvictions,
  getMotoringConvictions: (state) => state.motoringConvictions,
};
