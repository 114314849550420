export default {
  registration: "",
  type: null,
  make: null,
  model: null,
  abiCode: null,
  body: null,
  fuel: null,
  transmission: null,
  seats: null,
  doors: null,
  manufacturedDate: null,
  registeredDate: null,

  // Updated when refining
  driveSide: null,
  qPlate: null,
  purchasedDate: null,
  owner: null,
  keeper: null,
  value: null,
  modifications: [],
  imported: null,
  trackingDevice: null,
  securityDevices: {},
};
