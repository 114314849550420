import Identifier from "./identifier";

export function identifier({ next, router }) {
    let currentIdentifier = new Identifier();

    if (!currentIdentifier.identifier) {
        return router.push({ name: "landing" });
    }

    return next();
}
