import axios from "axios";

const request = (url, payload = {}, customConfig = {}) => {
  return new Promise((resolve, reject) => {
    let headers = {
      Accept: "application/json, text/plain, */*",
      ...customConfig.headers,
    };

    // Only use during local db dev
    url = `/api/${url}`;

    let method =
      typeof customConfig.method !== "undefined" ? customConfig.method : "GET";

    axios
      .request({
        url,
        method,
        headers,
        data: payload,
      })
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
};

export default request;
