<template>
  <footer
    id="main-footer-blog"
    class="d-none d-lg-block my-3 text-light text-small"
  >
    <oc-container>
      <sponsered-by />
      <div class="mt-3">
        <p>
          *20% of customers buying car insurance with us from 1st January to
          31st December 2021 paid this price or less (excluding optional
          extras). The price you will pay depends on individual circumstances
          and cover chosen.
        </p>
        <p>
          **For up to 14 days - if you are involved in an accident that isn’t
          your fault.
        </p>
      </div>
    </oc-container>
  </footer>
</template>

<script>
import SponseredBy from "./SponseredBy";
export default {
  name: "MainFooterBlog",
  components: { SponseredBy },
};
</script>
