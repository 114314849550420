import { commitPayload } from "@/utils/helpers/store";

export default {
  initialiseCustomer(context, customer) {
    commitPayload(context, customer);
  },

  /**
   * Update the customer state
   * @param commit
   * @param next
   * @param current
   */
  async updateCustomer({ commit }, { next, current }) {
    Object.keys(next).map((nextIndex) => {
      if (
        typeof current[nextIndex] !== "undefined" &&
        current[nextIndex] !== next[nextIndex]
      ) {
        commit(
          `set${nextIndex.replace(/^\w/, (c) => c.toUpperCase())}`,
          next[nextIndex]
        );
      }
    });
  },

  setCustomerNoClaimsDiscount({ commit }, payload) {
    commit("setNoClaimsDiscount", payload);
    commit("policy/setNoClaimsDiscount", payload, { root: true });
  },

  // /**
  //  * Save the current customer state
  //  * @param rootGetters
  //  * @param state
  //  * @param commit
  //  * @param rootState
  //  * @param params
  //  * @returns {Promise<*>}
  //  */
  // saveCustomer: async (
  //   { rootGetters, state, commit, rootState },
  //   params = {}
  // ) => {
  //   const requestParams = parseRequestParams(params, { rootState });
  //   return await setContactRequest(
  //     rootGetters["quote/getUuid"],
  //     { ...state },
  //     requestParams
  //   ).then(({ data }) => commit("setUuid", data.uuid));
  // }
};
