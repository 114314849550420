import Vue from "vue";
import VueRouter from "vue-router";
import { identifier } from "../utils/middleware";

Vue.use(VueRouter);

const routes = [
  {
    name: "landing",
    path: "/",
    component: () => import("*/views/LandingPage.vue"),
  },
  {
    name: "retrieve",
    path: "/retrieve",
    component: () => import("*/views/RetrieveQuote.vue"),
    props: route => ({
      quoteReference: route.query.quoteReference,
      dateOfBirth: route.query.dateOfBirth,
      postcode: route.query.postcode
    })
  },
  {
    name: "quote",
    path: "/quote",
    component: () => import("*/views/quote/InitialQuote.vue"),
    meta: {
        middleware: identifier,
    },
  },
  {
    name: "noQuote",
    path: "/no-quote",
    component: () => import("*/views/quote/NoQuote.vue"),
  },
  {
    name: "vehicleDetails",
    path: "/vehicle",
    component: () => import("*/forms/VehicleDetailsForm.vue"),
    meta: {
      middleware: identifier,
    },
  },
  {
    name: "driverDetails",
    path: "/driver",
    component: () => import("*/forms/DriverDetailsForm.vue"),
    meta: {
      middleware: identifier,
    },
  },
  {
    name: "policyDetails",
    path: "/policy",
    component: () => import("*/forms/PolicyDetailsForm.vue"),
    meta: {
      middleware: identifier,
    },
  },
  {
    name: "aboutUs",
    path: "/about-us",
    component: () => import("*/views/strapi/AboutUs.vue"),
  },
  {
    name: "carInsurance",
    path: "/car-insurance",
    component: () => import("*/views/strapi/CarInsurance.vue"),
  },
  {
    name: "FAQs",
    path: "/faqs",
    component: () => import("*/views/strapi/FAQs.vue"),
  },
  {
    name: "TermsAndConditions",
    path: "/terms-and-conditions",
    component: () => import("*/views/strapi/TermsAndConditions.vue"),
  },
  {
    name: "ContactUs",
    path: "/contact-us",
    component: () => import("*/views/strapi/ContactUs.vue"),
  },
  {
    path: "*",
    component: () => import("*/views/Error404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];

  if (!subsequentMiddleware) {
    return context.next;
  }

  return (...parameters) => {
    context.next(...parameters);

    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

    const context = { from, next, router, to };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({...context, next: nextMiddleware});
  }

  return next();
});

export default router;
