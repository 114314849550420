import { device } from "@onecall/utils";
import retrieveQuoteRequest from "@/requests/retrieve-quote";
import initialiseQuoteRequest from "@/requests/initialise-quote";
import initialSspQuoteLookupRequest from "@/requests/initial-ssp-quote-lookup";
import updateVehicleRequest from "@/requests/update-vehicle";
import updateCustomerRequest from "@/requests/update-customer";
import updatePolicyRequest from "@/requests/update-policy";
import createRefinedQuoteRequest from "@/requests/create-refined-quote";
import Identifier from "../../utils/identifier";

const state = {
  initialised: false,
  initialQuote: {},
};

const getters = {
  getInitialised: (state) => state.initialised,
  getInitialQuote: (state) => state.initialQuote,
};

const mutations = {
  setInitialised: (state, value) => (state.initialised = value),
  setInitialQuote: (state, value) => (state.initialQuote = value),
};

const actions = {
  initialiseQuote({ commit, dispatch }, identifier) {
    return new Promise((resolve, reject) => {
      if (!identifier) {
        resolve();
        return;
      }

      initialiseQuoteRequest(identifier)
        .then(async ({ data }) => {
          commit("setInitialQuote", data);
          dispatch("customer/initialiseCustomer", data.appurtenance.customer, {
            root: true,
          });
          dispatch("vehicle/initialiseVehicle", data.appurtenance.vehicle, {
            root: true,
          });
          dispatch("policy/initialisePolicy", data.appurtenance.policy, {
            root: true,
          });
          resolve({ data });
        })
        .catch((error) => reject(error));
    });
  },
  retrieveQuote({ commit, dispatch }, { quoteReference, postcode, dateOfBirth }) {
    return new Promise((resolve, reject) => {
      if (!quoteReference || !postcode || !dateOfBirth) {
        resolve();
        return;
      }

      retrieveQuoteRequest(quoteReference, postcode, dateOfBirth)
        .then(async ({ data }) => {
          new Identifier(data.uuid);
          commit("setInitialQuote", data);
          dispatch("customer/initialiseCustomer", data.appurtenance.customer, {
            root: true,
          });
          dispatch("vehicle/initialiseVehicle", data.appurtenance.vehicle, {
            root: true,
          });
          dispatch("policy/initialisePolicy", data.appurtenance.policy, {
            root: true,
          });
          resolve({ data });
        })
        .catch((error) => reject(error));
    });
  },
  getInitialQuote({ rootGetters, commit, dispatch }) {
    return initialSspQuoteLookupRequest({
      customer: {
        title: rootGetters["customer/getTitle"],
        firstName: rootGetters["customer/getFirstName"],
        lastName: rootGetters["customer/getLastName"],
        dateOfBirth: rootGetters["customer/getDateOfBirth"],
        emailAddress: rootGetters["customer/getEmailAddress"],
        telephoneNumber: rootGetters["customer/getTelephoneNumber"],
        address: rootGetters["customer/getAddress"],
        communicationPreferences: rootGetters["customer/getCommunicationPreferences"],
        occupations: rootGetters["customer/getOccupations"],
        noClaimsDiscount: rootGetters["customer/getNoClaimsDiscount"],
      },
      vehicle: {
        registration: rootGetters["vehicle/getRegistration"],
        type: rootGetters["vehicle/getType"],
        body: rootGetters["vehicle/getBody"],
        make: rootGetters["vehicle/getMake"],
        model: rootGetters["vehicle/getModel"],
        abiCode: rootGetters["vehicle/getAbiCode"],
        transmission: rootGetters["vehicle/getTransmission"],
        fuel: rootGetters["vehicle/getFuel"],
        doors: rootGetters["vehicle/getDoors"],
        seats: rootGetters["vehicle/getSeats"],
        engineSize: rootGetters["vehicle/getEngineSize"],
        manufacturedDate: rootGetters["vehicle/getManufacturedDate"],
        registeredDate: rootGetters["vehicle/getRegisteredDate"],
      },
      session: {
        deviceType: device.getType(),
        operatingSystemType: device.getOs(),
      },
    }).then(({ data }) => {
      commit("setInitialQuote", data);
      dispatch("customer/initialiseCustomer", data.appurtenance.customer, { root: true });
      dispatch("vehicle/initialiseVehicle", data.appurtenance.vehicle, { root: true });
      dispatch("policy/initialisePolicy", data.appurtenance.policy, { root: true });
      return data;
    });
  },
  updateVehicle({ rootGetters, commit }) {
    let currentIdentifier = new Identifier();

    return updateVehicleRequest(
      {
        registration: rootGetters["vehicle/getRegistration"],
        type: rootGetters["vehicle/getType"],
        make: rootGetters["vehicle/getMake"],
        model: rootGetters["vehicle/getModel"],
        body: rootGetters["vehicle/getBody"],
        fuel: rootGetters["vehicle/getFuel"],
        transmission: rootGetters["vehicle/getTransmission"],
        abiCode: rootGetters["vehicle/getAbiCode"],
        seats: rootGetters["vehicle/getSeats"],
        doors: rootGetters["vehicle/getDoors"],
        imported: rootGetters["vehicle/getImported"],
        owner: rootGetters["vehicle/getOwner"],
        keeper: rootGetters["vehicle/getKeeper"],
        driveSide: rootGetters["vehicle/getDriveSide"],
        qPlate: rootGetters["vehicle/getQPlate"],
        value: rootGetters["vehicle/getValue"],
        modifications: rootGetters["vehicle/getModifications"],
        trackingDevice: rootGetters["vehicle/getTrackingDevice"],
        securityDevices: rootGetters["vehicle/getSecurityDevices"],
        manufacturedDate: rootGetters["vehicle/getManufacturedDate"],
        registeredDate: rootGetters["vehicle/getRegisteredDate"],
        purchasedDate: rootGetters["vehicle/getPurchasedDate"],
      },
      currentIdentifier.identifier
    ).then(({ data }) => {
      commit("setInitialQuote", data);
      return data;
    });
  },
  updateCustomer({ rootGetters, commit }) {
    let currentIdentifier = new Identifier();

    return updateCustomerRequest(
      {
        title: rootGetters["customer/getTitle"],
        gender: rootGetters["customer/getGender"],
        firstName: rootGetters["customer/getFirstName"],
        lastName: rootGetters["customer/getLastName"],
        dateOfBirth: rootGetters["customer/getDateOfBirth"],
        emailAddress: rootGetters["customer/getEmailAddress"],
        telephoneNumber: rootGetters["customer/getTelephoneNumber"],
        licenceDate: rootGetters["customer/getLicenceDate"],
        licenceType: rootGetters["customer/getLicenceType"],
        employmentStatus: rootGetters["customer/getEmploymentStatus"],
        criminalConvictions: rootGetters["customer/getCriminalConvictions"],
        homeOwner: rootGetters["customer/getHomeOwner"],
        refusedCover: rootGetters["customer/getRefusedCover"],
        keptAtProposerAddress: rootGetters["customer/getKeptAtProposerAddress"],
        keptAddress: rootGetters["customer/getKeptAtProposerAddress"]
          ? rootGetters["customer/getAddress"]
          : rootGetters["customer/getKeptAddress"],
        accessOtherCars: rootGetters["customer/getAccessOtherCars"],
        usageFrequency: rootGetters["customer/getUsageFrequency"],
        permanentResidency: rootGetters["customer/getPermanentResidency"],
        dateToUK: rootGetters["customer/getDateToUK"],
        carsInFamily: rootGetters["customer/getCarsInFamily"],
        maritalStatus: rootGetters["customer/getMaritalStatus"],
        drivers: rootGetters["customer/getDrivers"],
        claims: rootGetters["customer/getClaims"],
        medicalConditions: rootGetters["customer/getMedicalConditions"],
        motoringConvictions: rootGetters["customer/getMotoringConvictions"],
        occupations: rootGetters["customer/getOccupations"],
        address: rootGetters["customer/getAddress"],
        communicationPreferences:
          rootGetters["customer/getCommunicationPreferences"],
      },
      currentIdentifier.identifier
    ).then(({ data }) => {
      commit("setInitialQuote", data);
      return data;
    });
  },
  updatePolicy({ rootGetters, commit }) {
    let currentIdentifier = new Identifier();

    return updatePolicyRequest(
      {
        coverDate: rootGetters["policy/getCoverDate"],
        coverType: rootGetters["policy/getCoverType"],
        pleasureMiles: rootGetters["policy/getPleasureMiles"],
        businessMiles: rootGetters["policy/getBusinessMiles"],
        keptLocation: rootGetters["policy/getKeptLocation"],
        vehicleUsage: rootGetters["policy/getVehicleUsage"],
        voluntaryExcess: rootGetters["policy/getVoluntaryExcess"],
        noClaimsDiscount: rootGetters["customer/getNoClaimsDiscount"],
        protectNoClaimsDiscount:
          rootGetters["policy/getProtectNoClaimsDiscount"],
      },
      currentIdentifier.identifier
    ).then(({ data }) => {
      commit("setInitialQuote", data);
      return data;
    });
  },
  getRefinedQuote() {
    let currentIdentifier = new Identifier();

    return createRefinedQuoteRequest(currentIdentifier.identifier).then(
      ({ data }) => data
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
