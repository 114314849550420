import Vue from "vue";
import router from "./router";
import store from "./store";
import App from "*/App.vue";
import { isProduction, isTesting } from "@/utils";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import Bugsnag from "@bugsnag/js";

// Disable Vue dev warning
Vue.config.productionTip = false;
Vue.filter("formatAmount", (value) => {
  const formatter = new Intl.NumberFormat("en", {
    style: "currency",
    currency: "gbp",
  });
  return formatter.format(value);
});

require("./plugins");

if (isProduction || isTesting) {
  Bugsnag.start({
    apiKey: window.config.bugsnagToken,
    appVersion: "1.0.1",
    plugins: [new BugsnagPluginVue(Vue)],
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
