export default {
  getRegistration: (state) => state.registration,
  getType: (state) => state.type,
  getMake: (state) => state.make,
  getModel: (state) => state.model,
  getBody: (state) => state.body,
  getAbiCode: (state) => state.abiCode,
  getFuel: (state) => state.fuel,
  getTransmission: (state) => state.transmission,
  getSeats: (state) => state.seats,
  getDoors: (state) => state.doors,
  getValue: (state) => state.value,
  getModifications: (state) => state.modifications,
  getImported: (state) => state.imported,
  getQPlate: (state) => state.qPlate,
  getTrackingDevice: (state) => state.trackingDevice,
  getSecurityDevices: (state) => state.securityDevices,
  getOwner: (state) => state.owner,
  getKeeper: (state) => state.keeper,
  getDriveSide: (state) => state.driveSide,
  getManufacturedDate: (state) => state.manufacturedDate,
  getRegisteredDate: (state) => state.registeredDate,
  getPurchasedDate: (state) => state.purchasedDate,
};
