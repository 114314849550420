<template>
  <header id="main-header" :class="['py-2 px-2 px-lg-6', { open }]">
    <oc-container class="px-2 px-md-6 px-lg-4 px-xl-6">
      <div>
        <img
          class="ocq-logo-image"
          src="/images/OQQ-header-logo.png"
          alt="One Quick Quote Logo"
        />
        <div id="nav-icon" :class="{ open }" class="float-lg-right">
          <div @click="toggleMenu">
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
      </div>
    </oc-container>
    <menu-navigation v-show="open" @open="toggleMenu" />
  </header>
</template>

<script>
import MenuNavigation from "*/layout/menu/MenuNavigation";

export default {
  name: "MainHeader",
  components: {
    MenuNavigation,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggleMenu() {
      this.open = !this.open;
      document.getElementsByTagName("html")[0].classList.toggle("menu-opened");
    },
  },
};
</script>
<style>
.ocq-logo-image {
  width: 50%;
  margin-left: -10px;
}
</style>
