<template>
  <div id="error-modal">
    <h3>Something went wrong</h3>
    <p>
      We were unable to complete the request because of an error. Please refresh
      the page and try again. If the problem continues then contact a member of
      our <a href="#" @click.prevent="openLiveChat">live chat team</a>.
    </p>
  </div>
</template>

<script>
import { openLiveChat } from "@onecall/live-chat";

export default {
  name: "ErrorModal",
  methods: {
    openLiveChat,
  },
};
</script>
