import Vue from "vue";
import Vuex, { createLogger } from "vuex";
import quoteStore from "./quote";
import customerStore from "./customer";
import vehicleStore from "./vehicle";
import policyStore from "./policy";
import getters from "./getters";
import mutations from "./mutations";
import { isDebug } from "@/utils";

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: isDebug,
  plugins: isDebug ? [createLogger()] : [],
  state: {
    loading: false,
  },
  getters,
  mutations,
});

store.registerModule("customer", customerStore);
store.registerModule("quote", quoteStore);
store.registerModule("vehicle", vehicleStore);
store.registerModule("policy", policyStore);

export default store;
