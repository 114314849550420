export default {
  getCoverDate: (state) => state.coverDate,
  getCoverType: (state) => state.coverType,
  getPleasureMiles: (state) => state.pleasureMiles,
  getBusinessMiles: (state) => state.businessMiles,
  getVoluntaryExcess: (state) => state.voluntaryExcess,
  getKeptLocation: (state) => state.keptLocation,
  getVehicleUsage: (state) => state.vehicleUsage,
  getProtectNoClaimsDiscount: (state) => state.protectNoClaimsDiscount,
  isBusinessMilesRequired: (state) => ["3", "4", "5", "6", "7", "10", "13"].includes(state.vehicleUsage),
};
