export default {
  setUuid: (state, payload) => (state.uuid = payload),
  setTitle: (state, payload) => (state.title = payload),
  setGender: (state, payload) => (state.gender = payload),
  setFirstName: (state, payload) => (state.firstName = payload),
  setLastName: (state, payload) => (state.lastName = payload),
  setDateOfBirth: (state, payload) => (state.dateOfBirth = payload),
  setNoClaimsDiscount: (state, payload) => (state.noClaimsDiscount = payload),
  setEmailAddress: (state, payload) => (state.emailAddress = payload),
  setTelephoneNumber: (state, payload) => (state.telephoneNumber = payload),
  setOccupations: (state, payload) => (state.occupations = payload),
  setCommunicationPreferences: (state, payload) => (state.communicationPreferences = payload),
  setAddress: (state, payload) => (state.address = payload),

  setUsageFrequency: (state, payload) => (state.usageFrequency = payload),
  setEmploymentStatus: (state, payload) => (state.employmentStatus = payload),
  setPermanentResidency: (state, payload) => (state.permanentResidency = payload),
  setDateToUK: (state, payload) => (state.dateToUK = payload),
  setMaritalStatus: (state, payload) => (state.maritalStatus = payload),
  setLicenceType: (state, payload) => (state.licenceType = payload),
  setLicenceDate: (state, payload) => (state.licenceDate = payload),
  setAccessOtherCars: (state, payload) => (state.accessOtherCars = payload),
  setCarsInFamily: (state, payload) => (state.carsInFamily = payload),
  setHomeOwner: (state, payload) => (state.homeOwner = payload),
  setRefusedCover: (state, payload) => (state.refusedCover = payload),
  setKeptLocation: (state, payload) => (state.keptLocation = payload),
  setKeptAddress: (state, payload) => (state.keptAddress = payload),
  setKeptAtProposerAddress: (state, payload) => (state.keptAtProposerAddress = payload),
  setDrivers: (state, payload) => (state.drivers = payload),
  setClaims: (state, payload) => (state.claims = payload),
  setMedicalConditions: (state, payload) => (state.medicalConditions = payload),
  setCriminalConvictions: (state, payload) => (state.criminalConvictions = payload),
  setMotoringConvictions: (state, payload) => (state.motoringConvictions = payload),
};
