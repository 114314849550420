export default {
  setCoverDate: (state, payload) => (state.coverDate = payload),
  setCoverType: (state, payload) => (state.coverType = payload),
  setPleasureMiles: (state, payload) => (state.pleasureMiles = payload),
  setBusinessMiles: (state, payload) => (state.businessMiles = payload),
  setVoluntaryExcess: (state, payload) => (state.voluntaryExcess = payload),
  setKeptLocation: (state, payload) => (state.keptLocation = payload),
  setVehicleUsage: (state, payload) => (state.vehicleUsage = payload),
  setNoClaimsDiscount: (state, payload) => (state.noClaimsDiscount = payload),
  setProtectNoClaimsDiscount: (state, payload) =>
    (state.protectNoClaimsDiscount = payload),
};
