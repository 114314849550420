/**
 * Run a local commit from an object
 * @example
 *    commitSingle(context, { name: "emailAddress", value: "email@address.com" });
 * Would run:
 *  context.commit('setEmailAddress', "email@address.com");
 *
 * @param commit
 * @param state
 * @param payload
 */
const commitSingle = ({ commit, state }, payload) => {
  if (state[payload.key] !== undefined) {
    let name = payload.key.replace(/^\w/, (c) => c.toUpperCase());
    commit(`set${name}`, payload.value);
  }
};

/**
 * Loop an object and pass each item to commitSingle
 * @param commit
 * @param state
 * @param payload
 */
export const commitPayload = ({ commit, state }, payload) => {
  if (typeof payload.key !== "undefined" && payload.value !== "undefined") {
    return commitSingle({ commit, state }, payload);
  }

  Object.keys(payload).map((payloadKey) => {
    commitSingle(
      { commit, state },
      { key: payloadKey, value: payload[payloadKey] }
    );
  });
};
