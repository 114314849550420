import Vue from "vue";

// Style guide
import Styleguide from "@onecall/style-guide";
import LiveChat from "@onecall/live-chat";

Vue.use(Styleguide);
Vue.use(LiveChat);

// Postcode Lookup
import PostcodeLookup from "@onecall/postcode-lookup";
import { webservicesApiUri } from "@/utils";

Vue.use(PostcodeLookup, {
  baseUrl: webservicesApiUri,
});

// Events
import Dispatcher from "@/utils/events/dispatcher";

Vue.use({
  install(Vue) {
    Vue.prototype.$events = new Dispatcher();
  },
});
