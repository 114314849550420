import { commitPayload } from "@/utils/helpers/store";

export default {
  initialisePolicy(context, customer) {
    commitPayload(context, customer);
  },

  /**
   * Update the customer state
   * @param commit
   * @param next
   * @param current
   */
  async updatePolicy({ commit }, { next, current }) {
    Object.keys(next).map((nextIndex) => {
      if (
        typeof current[nextIndex] !== "undefined" &&
        current[nextIndex] !== next[nextIndex]
      ) {
        commit(
          `set${nextIndex.replace(/^\w/, (c) => c.toUpperCase())}`,
          next[nextIndex]
        );
      }
    });
  },
};
