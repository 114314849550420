<template>
  <oc-container class="left-side-main-content">
    <div class="menu-container px-lg-6">
      <div id="menu-buttons-container">
        <ul>
          <li>
            <a href="#" @click.prevent="goToPage('landing')">Home</a>
          </li>
          <li>
            <a href="#" @click.prevent="goToPage('aboutUs')">About Us</a>
          </li>
          <li>
            <a href="#" @click.prevent="goToPage('carInsurance')"
              >Car Insurance</a
            >
          </li>
          <li>
            <a href="#" @click.prevent="goToPage('FAQs')">FAQs</a>
          </li>
          <li>
            <a href="#" @click.prevent="goToPage('TermsAndConditions')">T&Cs</a>
          </li>
          <li>
            <a href="#" @click.prevent="goToPage('ContactUs')">Contact Us</a>
          </li>
        </ul>
      </div>
      <div class="menu-footer">
        <sponsered-by />
      </div>
    </div>
  </oc-container>
</template>

<script>
import SponseredBy from "../main-footer/SponseredBy";
export default {
  name: "MenuNavigation",
  components: { SponseredBy },
  methods: {
    goToPage(page) {
      this.$router.push({ name: page }).then(() => this.$emit("open", false));
    },
  },
};
</script>

<style scoped lang="scss">
/* Animated Link Underline */
a {
  position: relative;
  color: #fff;
  cursor: pointer;
}
a:hover {
  color: #fff;
}
a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transform: scaleX(0);
  transition: transform 0.5s ease;
}
a:hover::before {
  transform: scaleX(1);
}
</style>
