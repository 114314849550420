export default {
  coverDate: null,
  coverType: null,
  pleasureMiles: null,
  businessMiles: 0,
  voluntaryExcess: null,
  vehicleUsage: null,
  keptLocation: null,
  noClaimsDiscount: null,
  protectNoClaimsDiscount: false,
};
