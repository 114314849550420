import policyInterface from "@/utils/interfaces/policy";
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

const state = {
  ...policyInterface,
  // More custom values here outside of the interface
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
