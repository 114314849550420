import { formatISO, isValid, subSeconds, isBefore } from "date-fns";

const STORAGE_KEY = "quoteIdentifier";
const EXPIRES_IN_SECONDS = 1800;

/**
 * Simple class to manage the identifier in the localStorage
 */
export default class {
  constructor(identifier = "") {
    this.identifier = identifier;
    this.validate();
  }

  validate() {
    // Do we have an identifier?
    if (!this.identifier || typeof this.identifier !== "string") {
      // Nope! Check the local storage
      let storedIdentifier = this.getLocal();
      if (storedIdentifier) {
        this.identifier = storedIdentifier.identifier;
        this.quoteDate = storedIdentifier.quoteDate;
      }
    } else {
      // An identifier was found. Add it to the localStorage
      this.updateLocal(this.identifier);
    }
  }

  getLocal() {
    let localIdentifier = false;
    if (window.localStorage) {
      let storage = window.localStorage.getItem(STORAGE_KEY);
      try {
        let parsedStorage = JSON.parse(storage);
        if (parsedStorage.identifier && parsedStorage.quoteDate) {
          let quoteDate = new Date(parsedStorage.quoteDate);
          localIdentifier =
            isValid(quoteDate) && isBefore(subSeconds(new Date(), EXPIRES_IN_SECONDS), quoteDate)
              ? parsedStorage
              : false;
        }
      } catch (exception) {
        localIdentifier = false;
      }
    }
    return localIdentifier;
  }

  updateLocal(identifier) {
    let quoteDate = formatISO(new Date());

    if (window.localStorage) {
      window.localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({ identifier, quoteDate })
      );
    }

    this.identifier = identifier;
    this.quoteDate = quoteDate;
  }

  clearLocal() {
    if (window.localStorage) {
      window.localStorage.removeItem(STORAGE_KEY);
    }
  }

  canBeLoaded() {
    return this.getLocal();
  }
}
