<template>
  <div>
    <p class="text-large" v-html="content.intro" />
    <p class="text-large text-light" v-html="content.secondIntro" />
    <ul class="icon-list">
      <li v-for="(item, index) in content.list" :key="index">
        <oc-icon :icon="item.icon" />
        <span class="ml-1 text-light text-large" v-html="item.text" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MainBlog",
  data() {
    return {
      content: {
        intro: `We’ll search our panel of insurance providers to find you our best deal from the likes of Aviva,
        LV and Axa – <span class="text-light">saving you the time and hassle associated with shopping around.</span>`,
        secondIntro: `Our car insurance prices start from as little as £153* -
        PLUS we’ve included some pretty impressive perks…`,
        list: [
          {
            icon: `triangle`,
            text: `<span class="text-bold">FREE</span> breakdown membership`,
          },
          {
            icon: `balance-scale`,
            text: `<span class="text-bold">FREE</span> motor legal cover`,
          },
          {
            icon: `cars`,
            text: `<span class="text-bold">FREE</span> replacement vehicle**`,
          },
          {
            icon: `user-shield`,
            text: `<span class="text-bold">Uninsured</span> driver promise`,
          },
          {
            icon: `tools`,
            text: `<span class="text-bold">24 hour</span> breakdown & claims service`,
          },
        ],
      },
    };
  },
};
</script>

<style scoped lang="scss">
.icon-list li:nth-child(1) .icon {
  margin-right: 4px;
}

.icon-list li:nth-child(5) .icon {
  margin-right: 8px;
}
</style>
