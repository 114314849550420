/**
 * Class to run the callbacks on the event
 */
export default class {
    /**
     * @param {string} eventName
     */
    constructor(eventName) {
        this.eventName = eventName;
        this.callbacks = [];
    }

    /**
     * @param {fn} callback
     */
    registerCallback(callback) {
        this.callbacks.push(callback);
    }

    /**
     * @param {fn} callback
     */
    unregisterCallback(callback) {
        const index = this.callbacks.indexOf(callback);
        if (index > -1) {
            this.callbacks.splice(index, 1);
        }
    }

    /**
     * @param data
     * @returns {Promise<boolean|*>}
     */
    async fire(data) {
        if (this.callbacks.length) {
            const asyncFilter = async (arr, callback) =>
                arr.reduce(
                    async (a, b) => [...(await a), ...((await callback(b)) ? [b] : [])],
                    []
                );

            return asyncFilter(this.callbacks, async (callback) => {
                const callbackResponse = await callback(data);
                return callbackResponse;
            });
        }
        return true;
    }
}
