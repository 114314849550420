import Vue from "vue";
import ErrorModal from "*/modals/ErrorModal";
import Bugsnag from "@bugsnag/js";

/**
 * Is the app in production mode?
 * @type {boolean}
 */
export const isProduction =
  window.config.environment.toLowerCase() === "production";

/**
 * Is the app in debug mode?
 * @type {boolean}
 */
export const isDebug = !!window.config.isDebug;

/**
 * Is the app in testing mode?
 * @type {boolean}
 */
export const isTesting = window.config.environment.toLowerCase() === "testing";

/**
 * Webservices URI
 * @type {string}
 */
export const webservicesApiUri = window.config.webservicesApiUri;

export const openErrorModal = (error, instance = null) => {
  if (isDebug) {
    console.error(error);
  }

  if (isProduction || isTesting) {
    Bugsnag.notify(new Error(error));
  }

  if (!instance) {
    instance = Vue;
  }
  instance.$modal.close();
  instance.$modal.show({
    closeable: false,
    component: ErrorModal,
  });
};

/**
 * Should the $route.path match the passed path?
 * @param $route
 * @param path
 * @returns {boolean}
 */
export const shouldRedirect = ($route, path) => {
  return $route.path.toLowerCase() !== path.toLowerCase();
};

export const jumpToTop = () =>
  (document.body.scrollTop = document.documentElement.scrollTop = 0);
