<template>
  <footer id="main-footer" class="mt-3 mb-2">
    <oc-container>
      <oc-row class="mb-3">
        <oc-col size="6" lg="2">
          <div id="logo">
            <oc-logo />
          </div>
        </oc-col>
        <oc-col size="6" lg="10" id="social-media-icons">
          <a
            href="https://www.facebook.com/onecalldirectinsurance"
            target="_blank"
            class="btn btn-facebook btn-rounded"
          >
            <oc-icon icon="facebook-f" color="white" />
          </a>
        </oc-col>
      </oc-row>
      <oc-row>
        <oc-col size="12" class="text-bold text-small">
          <ul>
            <li>
              <a
                href="https://www.onecallinsurance.co.uk/privacy-policy"
                target="_blank"
              >
                Privacy policy
              </a>
            </li>
            <li>
              <a
                href="https://www.onecallinsurance.co.uk/cookie-policy"
                target="_blank"
              >
                Cookie policy
              </a>
            </li>
            <li>
              <a
                href="https://www.onecallinsurance.co.uk/policy-documents"
                target="_blank"
              >
                Policy documents
              </a>
            </li>
            <li>
              <a
                href="https://www.onecallinsurance.co.uk/complaints"
                target="_blank"
              >
                Complaints
              </a>
            </li>
            <li>
              <a
                href="https://www.onecallinsurance.co.uk/contact-information"
                target="_blank"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </oc-col>
      </oc-row>
      <p class="text-small">
          &copy; One Quick Quote &trade; Official {{ year }} - All Rights reserved.
      </p>
      <p class="text-small text-light">
        One Quick Quote is a trading name of One Call Insurance Services Limited.
        Registered Office: First Point, Balby Carr Bank, Doncaster, South
        Yorkshire, DN4 5JQ. Authorised and regulated by the Financial Conduct
        Authority (FCA) for general insurance business. Registered in England and
        Wales, Company Number: 04693136. FCA Reg: 302961.
      </p>
    </oc-container>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      year: new Date().getFullYear(),
    }
  }
};
</script>
