export default {
  setRegistration: (state, payload) => (state.registration = payload),
  setType: (state, payload) => (state.type = payload),
  setMake: (state, payload) => (state.make = payload),
  setModel: (state, payload) => (state.model = payload),
  setAbiCode: (state, payload) => (state.abiCode = payload),
  setBody: (state, payload) => (state.body = payload),
  setFuel: (state, payload) => (state.fuel = payload),
  setTransmission: (state, payload) => (state.transmission = payload),
  setSeats: (state, payload) => (state.seats = payload),
  setDoors: (state, payload) => (state.doors = payload),
  setModified: (state, payload) => (state.modified = payload),
  setModifications: (state, payload) => (state.modifications = payload),
  setImported: (state, payload) => (state.imported = payload),
  setOwner: (state, payload) => (state.owner = payload),
  setKeeper: (state, payload) => (state.keeper = payload),
  setDriveSide: (state, payload) => (state.driveSide = payload),
  setQPlate: (state, payload) => (state.qPlate = payload),
  setValue: (state, payload) => (state.value = payload),
  setTrackingDevice: (state, payload) => (state.trackingDevice = payload),
  setSecurityDevices: (state, payload) => (state.securityDevices = payload),
  setManufacturedDate: (state, payload) => (state.manufacturedDate = payload),
  setRegisteredDate: (state, payload) => (state.registeredDate = payload),
  setPurchasedDate: (state, payload) => (state.purchasedDate = payload),

  setFromSearch: (state, payload) => {
    state.registration = payload.registration;
    state.make = payload.make.description;
    state.model = payload.model.description;
    state.abiCode = payload.model.code;
    state.doors = payload.doors;
    state.seats = payload.seats;
    state.body = payload.body.code;
    state.fuel = payload.fuel.code;
    state.transmission = payload.transmission.code;
    state.manufacturedDate = payload.manufacturedDate;
    state.registeredDate = payload.registeredDate;
    state.purchasedDate = payload.purchasedDate;
    state.type = payload.type;
    state.value = payload.value;
  },
};
