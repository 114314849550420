<template>
  <oc-app>
    <div v-if="initialised" id="main-container">
      <main-header />
      <oc-row>
        <oc-col class="d-none d-lg-inline" size="12" lg="6">
          <div id="left-side-container">
            <oc-container class="px-2 px-md-6 px-lg-4 px-xl-6 side-container">
              <div class="strapi-content">
                <main-blog />
              </div>
              <main-footer-blog />
            </oc-container>
          </div>
        </oc-col>
        <oc-col size="12" lg="6">
          <div
            id="right-side-container"
            class="bg-white text-black pt-2 pt-lg-3 px-2 px-md-6 px-lg-4 px-xl-6 side-container"
          >
            <router-view />
            <main-footer />
          </div>
        </oc-col>
      </oc-row>
    </div>
  </oc-app>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { shouldRedirect } from "@/utils";
import { OcSpinner } from "@onecall/style-guide";
import MainHeader from "*/layout/main-header/MainHeader";
import MainFooterBlog from "*/layout/main-footer/MainFooterBlog";
import MainFooter from "*/layout/main-footer/MainFooter";
import Identifier from "@/utils/identifier";
import MainBlog from "*/views/strapi/MainBlog";

export default {
  name: "App",
  components: { MainBlog, MainFooter, MainFooterBlog, MainHeader },
  computed: {
    ...mapGetters({
      loading: "getLoading",
      initialised: "quote/getInitialised",
    }),
  },
  methods: {
    ...mapMutations({
      setLoading: "setLoading",
      setInitialised: "quote/setInitialised",
    }),
    ...mapActions({
      initialiseQuote: "quote/initialiseQuote",
    }),
  },
  async beforeMount() {
    this.setLoading(true);
    let currentIdentifier = new Identifier();

    await this.initialiseQuote(currentIdentifier.identifier)
      .then(async (data) => {
        if (data && shouldRedirect(this.$route, "/quote")) {
          await this.$router.push({ name: "quote" });
        }

        this.setInitialised(true);
        this.setLoading(false);
      })
      .catch(async () => {
        if (shouldRedirect(this.$route, "/")) {
          await this.$router.push({ name: "landing" });
        }

        this.setInitialised(true);
        this.setLoading(false);
      });
  },
  watch: {
    loading(value) {
      this.$modal.close();
      if (value) {
        this.$modal.show({
          closeable: false,
          size: "xs",
          component: OcSpinner,
        });
      }
    },
  },
};
</script>
