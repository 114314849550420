export default {
  uuid: null,
  title: "",
  firstName: null,
  lastName: null,
  dateOfBirth: null,
  noClaimsDiscount: 0,
  emailAddress: null,
  telephoneNumber: null,
  occupations: [
    {
      industry: "",
      occupation: ""
    }
  ],
  communicationPreferences: {
    email: false,
    sms: false,
    telephone: false,
  },
  address: null,
  gender: null,
  maritalStatus: null,
  employmentStatus: null,
  usageFrequency: null,
  permanentResidency: null,
  dateToUK: null,
  licenceType: null,
  licenceDate: null,
  accessOtherCars: null,
  carsInFamily: null,
  refusedCover: null,
  homeOwner: null,
  keptLocation: null,
  keptAtProposerAddress: null,
  keptAddress: {},
  drivers: [],
  claims: [],
  medicalConditions: [],
  criminalConvictions: null,
  motoringConvictions: null,
};
