import customerInterface from "@/utils/interfaces/customer";
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

const state = {
  ...customerInterface,
  // More custom values here outside of the interface
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
