<template>
  <oc-row class="mb-3">
    <oc-col size="5" lg="3" class="border-radius">
      <oc-logo />
    </oc-col>
    <oc-col size="7" lg="5">
      <p class="text-small">
        Proudly brought to you by One Call insurance Services Limited
      </p>
    </oc-col>
    <oc-col size="12" lg="4" class="text-center">
      <img
        class="awards-images"
        src="/images/OQQ-awards-logo.svg"
        alt="Insurance choice awards and Customer Champions of the year - Broker finalists"
      />
    </oc-col>
  </oc-row>
</template>

<script>
export default {
  name: "SponseredBy",
};
</script>
