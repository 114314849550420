import request from "@/utils/request";

export default (quoteReference, postcode, dateOfBirth) => {
    let searchParams = new URLSearchParams({
        quoteReference: quoteReference,
        postcode : postcode,
        dateOfBirth: dateOfBirth
    });

    return request("quotes?" + searchParams.toString());
};
